.form{
  background-color: #ffffff;
  overflow: auto;
  margin: 0 auto;
  padding: 15px;
  z-index: 0;

  .formTitle{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: 10px;
    width: 100%;
    font-size: 30px;
    text-transform: capitalize;
    color: #0f314c;
    border-bottom: 3px solid #0f314c;
    span{
      margin: 5px 0;
    }
  }

  .sectionForm{
    padding: 5px;
  }
}

.flexGroup{
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
}

.w20{
  width: 20%;
}

.w100{
  width: 100%;
  padding: 0;
  margin: 0;
}

.invalidState{
  border: 1px #dc3545 solid !important;
}

.information{
  min-height: 20px;
  padding: 10px;
  margin: 5px 0 ;
  background-color: #f5f5f5;
  border: 1px solid #bbbbbb;
  border-radius: 2px;

  ul{
    padding: 0 20px;
  }
}

.alert{
  font-size: 16px;
  padding: 15px;
  margin: 20px 10px;
  border: 1px solid transparent;
  border-radius: 2px;
  font-weight: 700;
}

.alertWarning{
  background-color: #f0ad4e;
  border-color: #df8a13;
  color: #ffffff;
}

.alertDanger{
  color: #d9534f;
  padding: 5px 10px;
  border-left: 10px solid #ccc;
  border-left-color: #d9534f;
}

.informationTitle{
  font-size: 20px;
  font-weight: bold;
  color: #315e61;
  text-transform: uppercase;
  p{
    margin: 0;
  }
}

.filesList{
  display: flex;

  .itemData{
    margin: 0;
    font-size: 15px;
    margin-right: 5px;
  }

  .fa{
    margin: auto 0;
    margin-right: 5px;
    font-size: 20px;
  }
}

.kTextbox{
  width: 100% !important;
}

.kTextarea{
  width: 100% !important;
}

.item{
  width: 100%;
  .itemFields{
    border: 2px solid #dddddd;
    position: relative;
    padding: 10px 15px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    .fieldsArray{
      flex: 1 1 0;
      width: 0;
    }

    .fieldsGroup{
     box-sizing: border-box;
    }

    .fieldsGroupLeft{
      justify-self: flex-end;
      width: 33.3%;

    }

    .fieldsGroupW30{
      width: 30%;
    }

    .fieldsGroupW49 {
      width: 49%;
    }

    .fieldsGroupW100 {
      padding: 5px;
      width: 100%;
    }

  }

  .itemFieldsLeft{
    justify-content: flex-end;
  }

  .Label{
    display: flex;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: bold;

    i{
      font-size: 10px;
      padding-top: 3px;
      padding-left: 3px;
    }
  }

  //Checkbox
  .Checkbox{
    margin-right: 10px;
  }

  .checkboxGroup{
    .Checkbox{
      display: block;
    }
  }
}

.itemTitle{
  text-transform: capitalize;
}

.addressTitle{
  box-sizing: border-box;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
}

.addressSection{
  display: flex;
  justify-content: space-between;

  .item{
    width: 49%;
  }
}

.buttonAddress{
  margin: auto 0;
  padding: 5px;
  color: #ffffff;
  background-color: #798d8f;
  border-color: #5d5d5d;
  font-family: 'Lato', sans-serif;
  border-width: 2px;
  box-shadow: 1px 1px 4px rgb(0 0 0 / 40%)
}

.LightButton{
  border-color: #e4e7eb;
  color: #212529;
  background-color: #e4e7eb;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  font-size: 17px;
  padding: 0.375rem 0.75rem;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  text-transform: uppercase;
  margin: 5px;
  margin-left: 0;
  font-weight: 500;
  i{
    margin-right: 5px;
  }
}
.LightButton:hover {
  border-color: #c7cdd5;
  background-color: #ced3db;
  filter: none;
}

.invalidForm{
  text-transform: capitalize;
  color: red;
  margin: 10px 0;
  h2{
    margin: 10px 0;
    text-decoration: underline;
  }
  .item{
    margin: 5px 0;
    font-size: 18px;
  }
}
