@import "src/styles/colors";
@import "src/styles/kendo/elements/stepper";

.k-form .k-form-fieldset {
  margin: 10px 0 0;
}

.k-textbox {
  height: 36px;
  font-size: 14px;
  border-radius: 8px;
  padding: 6px 12px;
}

.k-button {
  border-radius: 8px;
  font-size: 16px;
}

.k-window-titlebar {
  background-color: $dark-blue;
  color: $white;
}

.k-animation-container {
  // todo: need to fix z-index
  z-index: 10002 !important;

  .k-popup .k-list-scroller .k-list,
  .k-list-container .k-list {
    display: flex;
    flex-direction: column;

    .k-item {
      font-weight: 700;
      font-size: 14px;
      padding: 5px 10px;
      border: none;
      box-shadow: none;

      .k-icon {
        color: $sky-blue;
      }
    }
  }
}

.k-dialog-wrapper {
  z-index: 10004 !important;

  &.WithOutPadding {
    .k-dialog-content {
      min-width: 600px;
      padding: 0;
    }
  }
}

.k-dialog-wrapper .k-dialog {
  max-width: calc(100vw - 50px);
  z-index: 10004 !important;
}

.FormWithLeftLabel {
  .k-form {
    .k-form-fieldset {
      .k-form-field {
        .k-label {
          align-items: flex-start;
          width: 40%;
        }

        .k-form-field-wrap {
          text-align: right;
        }
      }
    }

    .ButtonsWrapper {
      display: flex;
      flex-direction: row-reverse;

      .LeftButton,
      .LeftButton:hover,
      .RightButton,
      .RightButton:hover {
        color: $white;
        background: $blue;
        margin: 0 5px;
      }
    }
  }

  .LeftFormPart,
  .RightFormPart {
    .k-form {
      width: 100%;
      padding: 0 10px;
    }
  }
}

.k-animation-container {
  z-index: 10004 !important;
}

.k-grid {
  overflow: hidden;

  &.Grid {
    .k-grid-toolbar {
      padding: 0;
      background: $filthy-black;

      .ButtonsWrapper {
        display: flex;
        margin-left: auto;
      }

      .k-button,
      .k-button:hover,
      .k-button:active {
        background: transparent;
        border: none;
        box-shadow: none;
        color: $white;
        margin: 5px;
      }

      .k-button:hover {
        opacity: 0.8;
      }
    }

    .k-grid-container {
      height: 68vh;
    }
  }

  &.TabGrid {
    height: 100%;

    .k-grid-container {
      height: 45vh;
    }
  }
}

.MessageTextArea {
  textarea {
    &.k-input {
      resize: none;
    }
  }
}

.Group {
  .k-panelbar > .k-item > .k-link,
  .k-panelbar > .k-panelbar-header > .k-link {
    background-color: $light-grayish-cyan;
    color: $gray-blue;
    padding: 2px 10px;

    .k-panelbar-item-icon {
      color: $gray-blue;
    }
  }

  .k-panelbar > .k-item > .k-link.k-state-selected,
  .k-panelbar > .k-panelbar-header {
    width: 100%;

    & > .k-link.k-state-selected {
      background-color: $light-grayish-cyan;
      box-shadow: none;
    }
  }

  .k-panelbar {
    .k-panelbar-group {
      display: flex !important;
      flex-direction: column;

      & > .k-panelbar-item.k-level-1 .k-link {
        padding: 0 20px;
        background-color: $light-grayish-cyan;

        &:hover {
          background-color: $white-blue;
        }
      }

      & > .k-panelbar-item > .k-state-focus {
        box-shadow: none;
      }
    }

    .k-panelbar-item-text {
      width: 100%;
    }

    .k-panelbar-content {
      .k-button {
        border: none;
        font-size: 14px;
        border-radius: 0;
        background-color: transparent;
        display: flex;
        width: 100%;
        justify-content: space-between;

        &:hover {
          background-color: $white-blue;
        }
      }
    }
  }
}

.without-bg {
  .k-dropdown,
  .k-dropdown-wrap {
    background: transparent;
    border: none;

    .k-input {
      padding-top: 2px;
    }
  }

  &.white {
    .k-dropdown,
    .k-dropdown-wrap {
      color: $white;
    }
  }
}

th.k-header.active .k-grid-filter {
  color: $white;
  background-color: $blue;
}

.k-form .k-dropdown {
  width: auto;
}
