table tr th.BlueHeader {
  font-weight: normal;
  font-size: 1em;
  background-color: #1c649e;
  color: #fff;
  padding: 1px 0px 3px 0px;
  margin: 2px 15px;
}

//that is for And/Or words between tables(isn`t applied yet, needs to be fixed)
table.WithAndOrHeader tr th.Or::after {
  content: "Or";
  position: relative !important;
  top: 50% !important;
  right: -20px !important; /* Adjust the positioning as needed */
  transform: translateY(-50%) !important;
  background-color: white !important; /* Match the background color */
  padding: 0 5px !important; /* Add padding to create space */
  z-index: 1 !important; /* Ensure it's on top of the border */
}

table.WithAndOrHeader tr th.And::after {
  content: "And";
  position: absolute !important;
  top: 50% !important;
  right: -20px !important; /* Adjust the positioning as needed */
  transform: translateY(-50%) !important;
  background-color: white !important; /* Match the background color */
  padding: 0 5px !important; /* Add padding to create space */
  z-index: 1 !important; /* Ensure it's on top of the border */
}

.TablesContainer {
  display: flex;
  align-items: flex-start;
}

.SpanText {
  padding-left: 10px;
  padding-right: 10px;
  margin: 0px;
  vertical-align: bottom;
}

.SpanValue20 {
  padding-left: 10px;
  padding-right: 10px;
  min-height: 20px;
  border-bottom: 1px solid #1c649e;
  margin: 0px;
  display: inline-block;
  width: 20px;
}

.SpanValue30 {
  padding-left: 10px;
  padding-right: 10px;
  min-height: 20px;
  border-bottom: 1px solid #1c649e;
  margin: 0px;
  display: inline-block;
  width: 30px;
}

.SpanValue50 {
  padding-left: 10px;
  padding-right: 10px;
  min-height: 20px;
  border-bottom: 1px solid #1c649e;
  margin: 0px;
  display: inline-block;
  width: 50px;
}

.ImportantInformationTable {
  th {
    font-weight: normal;
  }

  th, td {
    font-size: 8px;
    padding: 5px;
  }

  .LeftPart {
    border-right: 1px solid #5fa3c7;
  }

  .RightPart {
    vertical-align: top;
    text-align: left;
  }

  p {
    margin-bottom: 5px;
  }
}

.DeadlinesTable {
  width: 90%;
  margin: 5px;
}

.ScheduleSection {
  padding-bottom: 22px;
}

.AssetAdded{
  color: #0fcc7b;

  td {
    color: #0fcc7b !important;
  }
}

.AssetRemoved{
  color: red;
  text-decoration: line-through;

  td {
    color: red !important;
  }
}

.AssetChanged{
  color: blue;

  td {
    color: blue !important;
  }
}

.AssetTotals {
  th, td, div {
    font-size: 1em;
  }
}