.container {
  display: flex;
}

.form {
  background-color: #ffffff;
  overflow: auto;
  margin: 0 auto;
  padding: 15px;
  z-index: 0;
  max-width: 70vw;

  .formTitle {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: 10px;
    width: 100%;
    font-size: 30px;
    text-transform: capitalize;
    color: #0f314c;
    border-bottom: 3px solid #0f314c;

    span {
      margin: 5px 0;
    }
  }

  .sectionForm {
    padding: 5px;
  }
}

.filesList {
  display: flex;

  .itemData {
    margin: 0;
    font-size: 15px;
    margin-right: 5px;
  }

  .fa {
    margin: auto 0;
    margin-right: 5px;
    font-size: 20px;
  }
}

.kTextbox {
  width: 100% !important;
}

.kTextarea {
  width: 100% !important;
}

.alert {
  font-size: 16px;
  padding: 15px;
  margin: 20px 10px;
  border: 1px solid transparent;
  border-radius: 2px;
  font-weight: 700;
}

.alertWarning {
  background-color: #f0ad4e;
  border-color: #df8a13;
  color: #ffffff;
}

.alertDanger {
  color: #d9534f;
  padding: 5px 10px;
  border-left: 10px solid #ccc;
  border-left-color: #d9534f;
}

.fieldsGroup {
  box-sizing: border-box;
}

.bordered {
  border: 2px solid #dddddd;
}

.item {
  width: 100%;
  margin-bottom: 10px;

  .itemFields {
    position: relative;
    padding: 10px 15px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 100%;

    .textHelper {
      position: absolute;
      right: 10px;
      top: 5px;

      i {
        font-size: 18px
      }
    }

    .textHelper:hover {
      cursor: pointer;
    }

    .fieldsArray {
      flex: 1 1 0;
      width: 0;
    }
  }

  .itemFields.bordered ~ .itemFields.bordered {
    border-top: none;
  }

  .itemFieldsLeft {
    justify-content: flex-start;
  }

  .itemFieldsRight {
    justify-content: flex-end;
  }

  .Label{
    display: flex;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: bold;

    i{
      font-size: 10px;
      padding-top: 3px;
      padding-left: 3px;
    }

  }

  .Checkbox {
    margin-right: 10px;
  }

  .checkboxGroup {
    .Checkbox {
      display: block;
    }
  }
}

.flexRow {  
  display: flex;
  flex-direction: row;
}

.flexRowReverse {
  padding: 10px 15px;
  display: flex;
  flex-direction: row-reverse;
}

.fieldsGroupLeft {
  justify-self: flex-end;
  width: 33.3%;
}

.fieldsGroupW15 {
  width: 15%;
}

.fieldsGroupW20 {
  width: 20%;
}

.fieldsGroupW25 {
  width: 25%;
}

.fieldsGroupW30 {
  width: 30%;
}

.fieldsGroupW33 {
  width: 33%;
}

.fieldsGroupW49 {
  padding: 0px 5px;
  width: 49%;
}

.fieldsGroupW50 {
  padding: 0px 5px;
  width: 50%;
}

.fieldsGroupW70 {
  width: 70%;
}

.fieldsGroupW75 {
  width: 75%;
}

.fieldsGroupW100 {
  width: 100%;
}

.paddingTop {
  padding-top: 5px;
}

.paddingLeft {
  padding-left: 20px;
}

.column {
  flex-direction: column;
}

.itemTitle {
  text-transform: capitalize;
  font-size: 18px;
  color: #315e61;
}

.addressTitle {
  box-sizing: border-box;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  padding: 10px 0px 0px 15px;
}

.invalidState {
  border: 1px #dc3545 solid !important;
}

.information {
  min-height: 20px;
  padding: 10px;
  margin: 5px 0;
  background-color: #f5f5f5;
  border: 1px solid #bbbbbb;
  border-radius: 2px;

  ul,
  ol {
    padding: 0 20px;
  }
}

.buttonAddress {
  margin: auto 0;
  padding: 5px;
  color: #ffffff;
  background-color: #798d8f;
  border-color: #5d5d5d;
  font-family: 'Lato', sans-serif;
  border-width: 2px;
  box-shadow: 1px 1px 4px rgb(0 0 0 / 40%)
}

.invalidForm {
  text-transform: capitalize;
  color: red;
  margin: 10px 0;

  h2 {
    margin: 10px 0;
    text-decoration: underline;
  }

  .item {
    margin: 5px 0;
    font-size: 18px;
  }
}
