@import "src/styles/colors.scss";

.DefaultModalForm {
  height: 100%;

  & > form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & > fieldset {
      margin: 0;

      & > div {
        margin: 10px 0;
      }
    }

    .ActionButtons {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.GreyBorder {
  border-bottom: 1px solid $grey-border;
}

.ButtonsWrapper {
  margin-bottom: 5px;
  display: flex;
  justify-content: flex-end;

  .GreenButton {
    background-color: $aqua-forest-green;
    color: $white;

    &:hover {
      background-color: $aqua-forest-green;
      color: $white;
      opacity: 0.8;
    }
  }
}

.FormButtonsWrapper {
  border-top: 1px solid $grey-border;
  padding-top: 6px;
  display: flex;

  .OptionalButton {
    background-color: $juniper-gray;
    color: $white;
    margin-right: 10px;
  }

  .MainButton {
    background-color: $dark-blue;
    color: $white;
    margin-left: auto;
  }

  .OptionalButton:hover {
    background-color: $juniper-gray;
    color: $white;
    opacity: 0.8;
  }

  .MainButton:hover {
    background-color: $dark-blue;
    color: $white;
    opacity: 0.8;
  }
}

.Title {
  text-align: left;
  font-size: 20px;
}

.SubTitle {
  text-align: left;
  font-size: 14px;
  padding: 20px 0 10px 0;
  border-bottom: 1px solid $grey-border;
}

.FormTitle {
  color: $fiord-dark;
}

.RegisterTitle {
  color: $aqua-forest-green;
  padding-bottom: 10px;
}

.ClearButtonWrapper {
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
}

.UnorderedList {
  div {
    display: flex;
    align-items: center;
  }
  label {
    text-align: left;
    font-size: 14px;
    padding: 10px;
    display: flex;
  }
  .IconColor {
    font-size: 14px;
    color: $aqua-forest-green;
  }
}
