.custom-stepper .k-progressbar {
  height: 4px;
  top: 18px;
}

.custom-step {
  width: 30px;
  height: 30px;
  border: 2px solid $dark-gray;
  border-radius: 50%;
  background-clip: padding-box;
  box-sizing: border-box;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
  cursor: pointer;
  margin-left: 4px;
}

.k-step-done .custom-step {
  color: $white;

  &.success {
    background-image: radial-gradient(circle at center, $green 0, $white-blue);
  }

  &.warning {
    background-image: radial-gradient(circle at center, $worn 0, $white-blue);
  }

  &.info {
    background-image: radial-gradient(
      circle at center,
      $sky-blue 0,
      $white-blue
    );
  }

  &.primary {
    background-image: radial-gradient(
      circle at center,
      $dark-blue 0,
      $white-blue
    );
  }

  &.danger {
    background-image: radial-gradient(circle at center, $red 0, $white-blue);
  }

  &.bg-pdf {
    background-image: radial-gradient(circle at center, $white 0, $light-gray);
    color: $red;
  }
}

.k-step:not(.k-step-done):not(.k-step-current) .custom-step {
  border: 2px solid $dark-gray;
}

.k-step-link .k-icon {
  font-size: 18px;
  color: $white;
}

.k-step-link .step-content {
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  border: 1px solid $grey-border;
  font-size: 18px;
  cursor: pointer;

  .date {
    font-size: 10px;
    color: $gray-blue;
  }

  .subject {
    font-size: 14px;
    color: $fiord-dark;
    white-space: initial;
  }
}
