.form-pdf-template {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.form-pdf-header {
  font-family: "RobotoCondensed-Regular", "RobotoCondensed-Italic", "RobotoCondensed-Bold" !important;
  line-height: 1.42857143 !important;
  font-size: .5em;
  background-color: #d0d5d9;
  padding: 0px 3px;
  margin: 6px 19px;
  display: flex;
  justify-content: space-between;
  text-align: right;
}

.form-pdf-header-text {
  font-weight: bold;
}

.form-pdf-footer-container{
  font-family: "RobotoCondensed-Regular", "RobotoCondensed-Italic", "RobotoCondensed-Bold" !important;
  line-height: 1.42857143 !important;
  padding: 5px 5px;
  position: absolute;
  width: 100%;
  bottom: 16px;
  font-size: 6px;
}

.form-pdf-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
  margin-right: 10px;
}

.form-pdf-footer-hr {
  width: 100%;
  color: #cfcfcf;
}

.form-pdf-footer-text {
  color: #5fa3c7;
  font-size: 7px;
}