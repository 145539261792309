.SubSection {
  border: 1px solid #1c649e;
}

.Disclaimer {
  font-size: 6px;
  color: #000000;
}

.Bordered {
  border: 1px solid #000000;
}
