.formTitle {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;
  font-size: 30px;
  text-transform: capitalize;
  color: #0f314c;
  border-bottom: 3px solid #0f314c;

  span {
    margin: 5px 0;
  }
}

.alert {
  font-size: 16px;
  padding: 15px;
  margin: 20px 10px;
  border: 1px solid transparent;
  border-radius: 2px;
  font-weight: 700;
}

.alertDanger {
  color: #d9534f;
  padding: 5px 10px;
  border-left: 10px solid #ccc;
  border-left-color: #d9534f;
}