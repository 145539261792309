.filesList{
    display: flex;
    margin-top: 5px;
  
    .itemData{
      margin: 0;
      font-size: 15px;
      margin-right: 5px;
    }
  
    .fa{
      margin: auto 0;
      margin-right: 5px;
      font-size: 20px;
    }
  }