.actions{
  display: flex;
  justify-content: center;
  margin: 0 auto;

  i{
    font-size: 20px;
  }
}

.btn{
  border: none;
  background: none;
  border-radius: 5px;
  padding: 5px;
}

.btn:hover{
  filter: none;
}

.btn:hover{
  background-color: #e4e7eb !important;
}

.W10{
  width: 10%;
}

.W5{
  width: 5%;
}

.w20{
  width: 20%;
}

.w50{
  width: 50%;
}

.col{
  width: 50%;
  text-align: left;
  font-size: 15px;
}

.value{
  display: flex;
  justify-content: space-between;
  padding: 3px;
  p{
    margin: 0;
  }
}

.added{
  color: #0fcc7b;

  input, span{
    color: #0fcc7b;
  }
}

.removed{
  color: red;

  span {
    color: red;
  }

  input{
    color: red;
    text-decoration: line-through;
  }
}

.changed{
  color: blue;

  input, span{
    color: blue;
  }
}

