@import "src/styles/colors.scss";

.Header {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  width: 100%;
  background-color: $dark-blue;
  color: $white;
  font-family: "Lato", sans-serif;
  border-bottom: 4px solid $orange;

  .SiteLink {
    display: flex;
    flex-direction: column;
    justify-content: left;
    justify-content: space-between;

    span {
      font-size: 16px;
      margin-left: 15px;
      margin-top: 10px;

      &:first-child {
        font-size: 26px;
        font-family: "Lato", sans-serif;
        font-weight: 400;
      }
    }
  }

  .HeaderLinkWrapper {
    align-self: center;
    float: right;

    .HeaderLinks {
      float: right;
      margin-left: 15px;
      border-radius: 3px;
      padding: 5px;
      text-align: center;

      .OpIcon {
        background: url("../../assets/images/icons/online-protest-icon.png");
        background-repeat: no-repeat;
        background-size: cover;
      }

      .PsIcon {
        background: url("../../assets/images/icons/property-search-icon.png");
        background-repeat: no-repeat;
        background-size: cover;
      }

      .Seal {
        background: url("../../assets/images/icons/seal-sm.png");
        background-repeat: no-repeat;
        background-size: cover;
      }

      .MapIcon {
        background: url("../../assets/images/icons/interactive-map-icon-sm.png");
        background-repeat: no-repeat;
        background-size: cover;
      }

      .Icon {
        float: left;
        width: 38px;
        height: 38px;
      }

      div {
        float: left;
        font-size: 18px;
        padding-top: 4px;
        padding-left: 8px;
        text-decoration: underline;
      }

      &:hover {
        background-color: $dark-gray;
      }
    }
  }
}

.Home {
  display: none;

  .HomeLink {
    float: right;
    margin-right: 2px;
    font-size: 18px;
    margin-top: 10px;
    border: 1px solid $white;
    padding: 5px;
    border-radius: 2px;
  }
}

.SiteIcon {
  width: 60px;
  height: 60px;
  margin: 0;
  background: url("../../assets/images/icons/online-forms-icon.png");
  background-repeat: no-repeat;
  background-size: cover;
  float: left;
}

@media (max-width: 767px) {
  .Home {
    display: flex;
  }

  .HeaderLinks {
    display: none;
  }
}
