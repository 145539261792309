.input{
  width: 100% !important;

  input{
    border-radius: 8px;
    width: 100%;
  }
}

.inputDisabled{
  background-color: #f2f5ff !important;
  width: 100% !important;
}

.invalidInput{
  border: 1px #dc3545 solid !important;
  width: 100% !important;

  input{
    border: none;
    width: 100%;
    border-radius: 0;
  }
}

.datePickerContainer{
  position: relative;
  width: 100%;

  .k-input-solid:focus-within {
    color: transparent;
  }

  .datePicker{
    width: 100%;
    color: transparent !important;
  }

  .datePickerFocus{
    border-color: #86b7fe;
    background-color: #ffffff;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    color: transparent;
  }

  .datePickerFocusInvalid{
    border-color: #dc3545;
    background-color: #ffffff;
    border-width: 1px;
    color: transparent;
  }

  .dateMaskedInput {
    height: 90%;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    border: none;
    left: 2px;
    width: 70%;
    z-index: 5;
    input{
      border: none !important;
      box-shadow: none !important;
      height: 30px;
      width: 100%;
    }

    input:focus{
      border: none;
      width: 100%;
    }
  }

  .dateMaskedInput:focus-within {
    border: none !important;
    box-shadow: none !important;
  }

}
