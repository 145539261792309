.FormContainer {
    font-size: 8px;

    .ValueLabelBelow {
        font-size: 7px;
    }

    .PageFooter {
        font-size: 8px;
    }
}

.ImportantInformationTable {
    th {
      font-weight: normal;
    }
  
    th, td {
      font-size: 7px;
      padding: 5px;
    }
  
    .RightPart {
      vertical-align: top;
      text-align: left;
    }

    p {
      margin-bottom: 5px;
    }
  }