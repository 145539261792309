@import "src/styles/colors.scss";

.FooterContainer {
  justify-content: space-between;
  background-color: $dark-blue;
  padding: 10px;
  display: flex;
  text-indent: 15px;
  color: $juniper-gray;
  width: 100%;
  flex: 0;

  a {
    margin-left: 2px;
  }
}
