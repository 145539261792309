@import "src/styles/colors.scss";

.Wrapper {
  background-color: $light-gray;
  padding: 15px;
}

.UserWrapper {
  background-color: $light-gray;
  padding: 15px;
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  justify-content: center;
}

.Header {
  display: flex;
  flex-direction: column;
  margin: 5px 0;

  .SubTitle {
    color: $dark-gray;
  }
}

.Title {
  display: flex;
  justify-content: space-between;
  text-align: left;
  font-size: 20px;
  color: $dark-blue;
  margin: 5px 0;

  & > button {
    color: $white;
    background-color: $aqua-forest-green;
    margin-top: -10px;
  }
}

.UnorderedList {
  margin: 5px 0;
  margin-left: 25px;
  color: $dark-gray;
}

.FormContent {
  display: flex;
  flex-direction: column;
  margin: 10px 0;

  .GetStarted {
    font-weight: 600;
    color: $dark-gray;
  }

  .FormList {
    width: 100%;
    max-height: 200px;
    overflow-y: scroll;
    background-color: $filthy-white;
    margin-top: 5px;

    .Form {
      & > button {
        padding: 10px;
        color: $filthy-gray;
        border: 1px solid $light-gray;
        width: 100%;
        border-radius: 0;
        display: flex;
        justify-content: flex-start;
        background-color: $filthy-white;

        & > span {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }

    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-track {
      background-color: $light-gray;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $dark-gray;
      border-radius: 20px;
      border: 1px solid $light-gray;
    }
  }
}

.Field {
  margin: 10px 0;
}

@media (max-width: 820px) {
  .UserWrapper {
    margin: 10px;
  }
}
