$white: #fff;
$black: #000000;
$dark-white: #edf2fa;
$bright-gray: #fafcff;
$red-hover: #cc3535;
$red: #ea3939;
$fiord-dark: #435e65;
$light-gray: #eeeeee;
$aqua-forest-green: #5c966e;
$grey-border: #dddddd;
$gray-blue: #285578;
$dark-blue: #0f314c;
$juniper-gray: #798d8f;
$dark-green: #224547;
$orange: #fdd87f;
$worn: #c0a111;
$white-orange: #fff0cb;
$dark-gray: #777777;
$filthy-green: #315e61;
$dark-green: #3c763d;
$white-green: #dff0d8;
$light-green: #d6e9c6;
$blue: #0078d7;
$hover-blue: #0470c9;
$obscurely-blue: #004578;
$filthy-black: #333333;
$sky-blue: #1797be;
$white-gray: #edf1f2;
$mint: #23b7e5;
$light-gray: #e7ecee;
$mine-black: #222222;
$cod-black: #080808;
$link-water: #d9edf7;
$atomic-tangerine-orange: #ffaa6e;
$gray-blue: #58666e;
$blue-gray: #d6edff;
$white-blue: #d9e0e2;
$gray-red: #a3a3a345;
$light-grayish-cyan: #edf1f2;
$green: #008000;
$shadow: #b9c2c4;
$activeLink: #bad5fd;
$filthy-gray: #555454;
$selected-white: #fafeff;
$filthy-white: #f9fafb;
